import { get } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TRY_SIGNUP, TRY_SIGN_WITH_SSO, updateAuth } from '../../redux/AuthSlice';
import { selectPortalInfo } from '../../redux/PortalInfoSlice';
import { updateUser } from '../../redux/UserSlice';
import { firebase } from '../auth/firebase';
import { signInHandleAndIdentifyUser } from './signInHandleAndIdentifyUser';

export function ProvideAuth({ children }) {
  const dispatch = useDispatch();
  const portalInfo = useSelector(selectPortalInfo);
  const hasUser = JSON.parse(localStorage.getItem('user'));
  const notUser = get(hasUser, 'userNotFound');
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (!user) {
        dispatch(updateAuth({}));
        dispatch(updateUser({}));
        console.log('no sub');
      } else {
        dispatch(updateAuth(user));
        firebase.analytics().logEvent('login');
        console.log('sub ok');
        sessionStorage.removeItem(TRY_SIGN_WITH_SSO);
        const isSignup = sessionStorage.getItem(TRY_SIGNUP);
        if (isSignup !== 'true') {
          const handleUser = await signInHandleAndIdentifyUser(portalInfo, user, false);
          dispatch(updateUser(handleUser));
        }
      }
    });
    return () => !notUser && unsubscribe();
  }, [dispatch, notUser]);

  return children;
}
