import { HuiLoading, HuiPanel } from 'handle-ui';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCheckoutToken } from '../../../actions/invoices/getCheckoutToken';
import { getPaymentRequestByToken } from '../../../actions/payment-requests/getPaymentRequestByToken';
import HuiPanelPayment from '../../../components/checkout/HuiPanelPayment';
import HuiPanelPaymentAmount from '../../../components/checkout/HuiPanelPaymentAmount';
import HuiPanelPaymentCustom from '../../../components/checkout/HuiPanelPaymentCustom';
import HuiPanelPaymentInvoices from '../../../components/checkout/HuiPanelPaymentInvoices';
import HuiPanelPaymentMessage from '../../../components/checkout/HuiPanelPaymentMessage';
import invoices from '../../../components/checkout/invoices.json';
import Header from '../../../components/layout/header/Header';
import { forceCloseLoading, openLoading, selectLoading } from '../../../redux/LoadingSlice';
import { resetPaymentData, updatePaymentDataInfo, updatePaymentRequest } from '../../../redux/PaymentDataSlice';
import { selectPortalInfo } from '../../../redux/PortalInfoSlice';
import UtilPaymentMethods from '../../../utils/utilPaymentMethods';
import ContactInformation from './ContactInformation';
import OneTimePaymentCheckout from './OneTimePaymentCheckout';
import RequestInformation from './RequestInformation';

const Loading = () => {
  const loading = useSelector(selectLoading);
  return loading ? <HuiLoading className="hui-payment-form-loading" isOpen /> : null;
};

const paymentTitle = {
  invoices: 'Payment Request',
  custom: 'Make a Payment',
  amount: 'Payment Request',
};

const paymentData = {
  name: '',
  email: '',
  total: 0,
  frequency: 'ONE_TIME',
  paymentTypeError: false,
  reference: '',
  projectName: '',
  projectAddress: '',
  notes: '',
  scheduledAt: null,
  paymentMethod: UtilPaymentMethods.CREDIT_CARD,
  type: 'amount',
};

const PublicPay = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const portalInfo = useSelector(selectPortalInfo);
  const [token, setToken] = useState('');
  const [validRequest, setValidRequest] = useState(null);
  const [fields, setFields] = useState(paymentData);

  const query = queryString.parse(search);
  const { token: paymentRequestToken } = query || '';

  const paymentsType = {
    invoices: <HuiPanelPaymentInvoices invoices={invoices} />,
    custom: <HuiPanelPaymentCustom fields={fields} handleChange={handleChange} />,
    amount: <HuiPanelPaymentAmount amount={fields.amount} />,
  };

  const getPaymentType = paymentsType[fields.type];
  const getTitle = paymentTitle[fields.type];

  // reset payment data when component unmount
  useEffect(() => {
    return () => {
      dispatch(resetPaymentData());
    };
  }, [dispatch]);

  useEffect(() => {
    async function handleGetPaymentRequestByToken(paymentRequestToken) {
      try {
        if (paymentRequestToken) {
          setValidRequest(true);
          const paymentRequest = await getPaymentRequestByToken(paymentRequestToken);
          dispatch(
            updatePaymentRequest({
              paymentRequest,
              subtotal: paymentRequest.amount,
              currencyCode: paymentRequest.currencyCode,
              paymentRequestToken,
            }),
          );
          setFields({
            ...fields,
            info: {
              name: paymentRequest.name,
              email: paymentRequest.email,
            },
            ...paymentRequest,
            total: paymentRequest.amount,
          });
        } else {
          setFields({ hasError: false });
          setValidRequest(false);
        }
      } catch (error) {
        console.log('error', error);
        setFields({ paymentRequestToken, ...error, hasError: true });
        setValidRequest(false);
      }
    }

    handleGetPaymentRequestByToken(paymentRequestToken);
  }, [paymentRequestToken]);

  useEffect(() => {
    async function getToken() {
      const apiToken = await getCheckoutToken();
      setToken(apiToken);
    }

    if (!token && portalInfo?.id) {
      getToken();
    }
  }, [setToken, portalInfo]);

  // control loading
  useEffect(() => {
    async function loading() {
      dispatch(openLoading());
      if (token) {
        await dispatch(forceCloseLoading({}));
      }
    }

    loading();
  }, [dispatch, token]);

  useEffect(() => {
    if (!fields.hasError && portalInfo?.settings) {
      const { isAchEnabled, isCreditCardEnabled, isOneTimePaymentEnabled } = portalInfo?.settings;
      if (!isOneTimePaymentEnabled) {
        setValidRequest(false);
        return;
      }
      if (!isAchEnabled && !isCreditCardEnabled) {
        setFields({
          ...fields,
          message: 'The payment methods for this transaction have been disabled',
          error: 'Bad Request',
          statusCode: 400,
          hasError: true,
        });
        setValidRequest(false);
        return;
      }
    }
  }, [dispatch, portalInfo, fields]);

  if (validRequest === null) {
    return <Loading />;
  }

  return validRequest ? renderCheckout() : navigate('/invalid-request', { state: { fields } });

  function renderCheckout() {
    return (
      <>
        <Loading />
        <Header onlyLogo />
        <div className="hui-header-padding position-relative">
          <div className="container-md container-fluid container-xl">
            <div className="row py-4">
              <div className="col-lg-7 col-xl-8">
                <HuiPanel className="hui-payment-panel overflow-hidden position-relative d-flex flex-column mb-4">
                  <HuiPanelPayment title={getTitle}>
                    {getPaymentType}
                    <HuiPanelPaymentMessage fields={fields} />
                  </HuiPanelPayment>
                </HuiPanel>
                <ContactInformation onChange={handleContactInfoChange} />
                <RequestInformation fields={fields} className="d-none d-lg-block mt-4" />
              </div>
              <div className="col-lg-5 col-xl-4 mt-4 mt-lg-0">
                <OneTimePaymentCheckout token={token} onChange={handleChange} />
              </div>
              <RequestInformation fields={fields} className="d-block d-lg-none mt-4" />
            </div>
          </div>
        </div>
      </>
    );
  }

  function handleChange(e) {
    setFields({ ...fields, [e.target.name]: e.target.value });
  }

  function handleContactInfoChange(e) {
    const { info: oldInfo = {} } = fields;
    const info = {
      ...oldInfo,
      [e.target.name]: e.target.value,
    };
    setFields({ ...fields, info });
    dispatch(updatePaymentDataInfo({ info }));
  }
};

export default PublicPay;
