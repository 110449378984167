import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import toastr from 'toastr';
import { sendEmailVerification } from '../../../actions/auth/sendEmailVerification';
import { signOut } from '../../../actions/auth/signOut';
import { verifyEmail } from '../../../actions/auth/verifyEmail';
import Header from '../../../components/layout/header/Header';
import { logoutAuth, selectAuth } from '../../../redux/AuthSlice';
import { closeModal, openModal } from '../../../redux/ModalSlice';
import { updateUser } from '../../../redux/UserSlice';

const ConfirmEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector(selectAuth);
  const { search } = useLocation();
  const query = queryString.parse(search);
  const { oobCode } = query || '';

  const [verified, setVerified] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchUser() {
      const modal = {
        type: 'confirmEmail',
        confirmAction: () => {
          dispatch(closeModal({}));
          return navigate('/login');
        },
        showConfirmButton: false,
        showCancelButton: false,
        isOpen: true,
        keepOpen: true,
        hideClose: true,
        hideActions: true,
        size: 'full',
      };

      if (oobCode) {
        try {
          const verification = await verifyEmail(oobCode);

          if (verification) {
            await dispatch(logoutAuth());
            await signOut();
            await dispatch(updateUser({}));
            setVerified(true);
            dispatch(openModal(modal));
          }
        } catch (e) {
          dispatch(openModal(modal));
        }
      }

      const user = (await auth) || {};
      const userName = (await user.displayName) || '';
      const userEmail = (await user.email) || '';

      setDisplayName(userName);
      setEmail(userEmail);
    }

    if (!loading) {
      fetchUser();
      setLoading(true);
    }
  }, [dispatch, auth, setDisplayName, setEmail, oobCode, navigate, setVerified, loading, setLoading]);

  return (
    <>
      <Header onlyLogo />
      <div className="hui-header-padding position-relative">
        {verified ? (
          <div className="p-5 text-center">
            <h1>Email Verified</h1>

            <Link className="" to="/login">
              Log in
            </Link>
          </div>
        ) : (
          <div className="p-5 text-center">
            <h1 className="text-center hui-title mb-4">Verify your email</h1>

            <div className="w-50 mx-auto mb-5">
              Hi{displayName ? ` ${displayName}` : ''}! Thanks for signing up! We just need you to verify your email
              address to complete setting up your account.
              <br />
              <br />
              <strong>We've sent a verification email to: {email}.</strong>
              <br />
              <br />
              Click the link in your email to verify your account. If you have not received the email after a few
              minutes, check your spam folder or click below to resend the email.
            </div>

            <button onClick={() => handleSendEmailVerification()} className="hui-btn hui-btn-xl hui-btn-green">
              Resend verification link
            </button>

            <br />
            <br />

            <div>
              Issues with the verification process or entered the wrong email? Please sign up with{' '}
              <Link className="" to="/logout">
                another
              </Link>{' '}
              email address.
            </div>
          </div>
        )}
      </div>
    </>
  );

  async function handleSendEmailVerification() {
    try {
      const result = await sendEmailVerification();

      if (result.isEmailVerificationSent) {
        return toastr.success('please check your email');
      }

      if (result.isEmailVerified) {
        return toastr.info('Email already verified');
      }
    } catch (error) {
      toastr.error(error);
    }
  }
};

export default ConfirmEmail;
