import { HuiPanel, HuiPanelContent } from 'handle-ui';
import HuiTable from '../../components/table/HuiTable';
import { size } from 'lodash';
import HuiTableCell from '../table/HuiTableCell';
import moment from 'moment';
import HuiTableInvoiceDate from '../table/HuiTableInvoiceDate';
import currencyFormatter from 'currency-formatter';
import HuiTableTotal from '../table/HuiTableTotal';
import HuiTableBalance from '../table/HuiTableBalance';

let columns = [
  {
    field: 'invoiceDate',
    label: 'Invoice Date',
    width: '83px',
    getValue: ({ value }) => moment(value).format('M/DD/YYYY'),
    cellRenderer: (props) => <HuiTableInvoiceDate {...props} />,
  },
  {
    field: 'dueDate',
    label: 'Due Date',
    width: '87px',
    getValue: ({ value }) => moment(value).format('M/DD/YYYY'),
    cellRenderer: (props) => <HuiTableInvoiceDate {...props} />,
  },
  {
    field: 'invoiceNumber',
    label: 'Invoice Number',
    width: '100px',
    cellRenderer: (props) => <HuiTableCell {...props} />,
  },
  {
    field: 'jobID',
    label: 'Job ID',
    width: '100px',
    cellRenderer: (props) => <HuiTableCell {...props} />,
  },
  {
    field: 'poNumber',
    label: 'PO Number',
    width: '100px',
    cellRenderer: (props) => <HuiTableCell {...props} />,
  },
  {
    field: 'totalAmount',
    label: 'Total Amount',
    width: '100px',
    className: 'text-end',
    headerCellRenderer: ({ column }) => <div className="d-block w-100 text-end">{column.label}</div>,
    getValue: ({ value }) => currencyFormatter.format(value, { code: 'USD' }),
    cellRenderer: (props) => <HuiTableTotal {...props} />,
  },
  {
    field: 'balance',
    label: 'Balance',
    fixed: true,
    className: 'text-end',
    headerCellRenderer: ({ tableManager, column }) => <div className="d-block w-100 text-end">{column.label}</div>,
    getValue: ({ value }) => currencyFormatter.format(value, { code: 'USD' }),
    cellRenderer: (props) => <HuiTableBalance className={'hui-color-red'} {...props} />,
  },
];

columns = columns.map((column, i) => {
  return { ...column, id: i, sortable: false };
});

const HuiPanelPaymentInvoices = (props) => {
  const { invoices } = props;
  const isLoading = size(invoices) ? false : true;

  return (
    <div>
      <HuiPanel noBorder boxShadow className="overflow-hidden position-relative mb-3">
        <HuiPanelContent className="d-flex align-items-center py-0 px-2">
          <HuiTable
            isLoading={isLoading}
            className="hui-table hui-table-no-border hui-table-fluid"
            columns={columns}
            rows={invoices.rows}
            totalRows={invoices.count}
            isPaginated={false}
          />
        </HuiPanelContent>
      </HuiPanel>
    </div>
  );
};

export default HuiPanelPaymentInvoices;
