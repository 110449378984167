import React, { useState } from 'react';
import { HuiInput } from 'handle-ui';
import toastr from 'toastr';
import { emailInvoice } from '../../../actions/invoices/emailInvoice';
import { emailPayment } from '../../../actions/payment/emailPayment';
import { size } from 'lodash';
import { getInvoiceEvents } from '../../../actions/invoices/getInvoiceEvents';
import { updateInvoiceEvents } from '../../../redux/InvoiceEventsSlice';
import { useDispatch } from 'react-redux';

const ModalSendEmail = (props) => {
  const { close, modal } = props;
  const { sendEmailType, sendEmailId } = modal;

  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [emails, setEmails] = useState([]);
  const [hasError, setHasError] = useState(false);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <HuiInput
            error={hasError}
            inputProps={{ minLength: 9 }}
            label="Enter emails (comma separated)"
            id="email"
            name="email"
            type="email"
            multiple
            value={email}
            onChange={handleChange}
            size="large"
            topLabel
            required
          />
        </div>
      </div>
      <div className="d-flex align-items-center mt-3">
        <div className="me-2 flex-grow-1">
          <button onClick={handleCancel} className="hui-btn hui-btn-outline hui-btn-red">
            Cancel
          </button>
        </div>

        <div className="ms-auto">
          <button
            onClick={handleSubmit}
            disabled={hasError || !email || !size(emails)}
            className="hui-btn hui-btn-green">
            Send
          </button>
        </div>
      </div>
    </>
  );

  function handleCancel() {
    resetEmail();
    close();
  }

  function resetEmail() {
    setEmails([]);
    setEmail('');
    setHasError(false);
  }

  function handleChange(e) {
    setEmail(e.target.value);
    handleValidate(e.target.value);
  }

  function handleValidate(value) {
    const arrayEmails = (value || '').split(',').map(function(e) {
      return e.trim();
    });

    const pattern = /^([+\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

    const validateEmailByString = (e) => {
      const emailId = e.replace(/(\r\n|\n|\r| )/gm, '');
      return pattern.test(emailId);
    };

    const isAllEmailValid = arrayEmails.every(validateEmailByString);

    if (isAllEmailValid) {
      setEmails(arrayEmails);
      setHasError(false);
    } else {
      setHasError(true);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const payload = {
      'emails': emails,
    };

    try {
      let result;

      if (sendEmailType === 'invoice') {
        result = await emailInvoice(sendEmailId, payload);
      }

      if (sendEmailType === 'payment') {
        result = await emailPayment(sendEmailId, payload);
      }

      if (result) {
        toastr.success('Email sent to successfully!');
        if (sendEmailType === 'invoice') {
          setTimeout(async () => {
            const invoiceEvents = await getInvoiceEvents(sendEmailId);
            await dispatch(updateInvoiceEvents(invoiceEvents));
          }, 3000);
        }
        resetEmail();
        close();
      } else {
        toastr.error('Please try to send the email again');
      }
    } catch (error) {
      toastr.error(error);
    }
  }
};

export default ModalSendEmail;
