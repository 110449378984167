import { HuiAlert } from 'handle-ui';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectPortalInfo } from '../../redux/PortalInfoSlice';

const HuiPanelPaymentMessage = (props) => {
  const portalInfo = useSelector(selectPortalInfo);

  const { name } = portalInfo || {};

  const { fields } = props || {};
  const { email, description, orderNumber } = fields || '';
  const orderNumberText = orderNumber ? `Order #: ${orderNumber}` : null;
  const infoText =
    description && orderNumberText ? `${description} - ${orderNumberText}` : description ?? orderNumberText;
  return (
    <>
      <HuiAlert title="Info:" radius="xl" icon="info" className="mb-2" inline background gray border>
        {infoText}
      </HuiAlert>

      <HuiAlert border title="100% Secure" radius="xl" icon="white-check" shadow background black>
        {name}, has sent a verified payment link to your email <u>{email}</u> through our trustworthy{' '}
        <b>Payment System</b>.
      </HuiAlert>
    </>
  );
};

export default HuiPanelPaymentMessage;
